import {SELECTORS, STCK_STORE} from '@oneaudi/stck-store';
import {OBJECT_UTILS} from '../stockcars-utils-bundle';

export default class SuggestedVehicleDTO {
	constructor(vehicle_, detailsLinkURL_ = '', tileTabs_ = '') { // eslint-disable-line max-statements
		if (!vehicle_) {
			throw new Error('not car object to initialize SuggestedVehicleDTO');
		}
		let options = {
			url: detailsLinkURL_,
			percentage: SuggestedVehicleDTO._getPercentage(vehicle_),
			suggestedImage: SuggestedVehicleDTO._getImage(vehicle_),
			suggestedCar: true,
			tileTabs: tileTabs_,
			fuelTypes: SuggestedVehicleDTO._addFuelTypes(vehicle_),
			isElectrical: SuggestedVehicleDTO._checkElectrical(vehicle_),
			favorite: SuggestedVehicleDTO._addFavoriteStatus(vehicle_)
		};
		this.vehicleData = Object.assign({}, vehicle_, options);
	}


	/**
	 * Add favorite status before rendering
	 * @param {Object} vehicle_ - vehicle_
	 * @returns {Boolean} favorite status
	 */
	static _addFavoriteStatus(vehicle_) {
		return SELECTORS.VEHICLES.isFavoriteVehicle(STCK_STORE.state, vehicle_.id);
	}

	/**
	 * addFuelTypes
	 * @method addFuelTypes
	 * @param {Object} vehicle_ vehicle object for preparing with fuel types (also hybrids)
	 * @returns {String} prepared string
	 */
	static _addFuelTypes(vehicle_) {
		let fuels = [];
		if (vehicle_.io && (vehicle_.io.hasNedc || vehicle_.io.hasWltp) && vehicle_.io.fuels.length) {
			vehicle_.io.fuels.forEach(item => {
				if (!!item.fuel) {
					let type = window.i18n[`sc.tiles.fuel.type.${item.fuel.toLowerCase()}.label`] || '';
					fuels.push(type);
				}
			});
		}
		return fuels.join(' / ');
	}

	/**
	 * checkElectrical
	 * @method checkElectrical
	 * @param {Object} vehicle_ vehicle object for checking if it is electrical
	 * @returns {Boolean} prepared boolean
	 */
	static _checkElectrical(vehicle_) {
		let isElectrical = false;
		const engineType = OBJECT_UTILS.getNestedObject(vehicle_, 'io.engineType'.split('.')) || '';
		if (vehicle_.io && vehicle_.io.fuels && vehicle_.io.fuels.length) {
			isElectrical = !!(vehicle_.io.fuels.filter((item = {}) => {
				return (item.fuel === "ELECTRICAL") && (engineType !== "OVC_HEV");
			}).length);
		}
		return isElectrical;
	}

	static _getPercentage(vehicle_) {
		return (vehicle_.scoringPercentage || vehicle_.displayPercentage) ? Math.round(vehicle_.displayPercentage || vehicle_.scoringPercentage) : '';
	}

	static _getImage(vehicle_ = {}) {
		let image = '', renderImage, fallbackImage;
		const pictureUrls = OBJECT_UTILS.getNestedObject(vehicle_, "used.pictureUrls".split(".")) || [];
		if (vehicle_.type === 'U' && pictureUrls.length) {
			image = pictureUrls[0];
		}
		else if (Array.isArray(vehicle_.pictures) && (renderImage = vehicle_.pictures.find(pic => pic.key === "vtp400n1bg2"))) {
			image = renderImage.url;
		}
		else if (Array.isArray(vehicle_.fallbackPictures) && (fallbackImage = vehicle_.fallbackPictures.find(pic => pic.key === "render_4x3"))) {
			image = fallbackImage.url;
		}
		return image;
	}
}
