(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("core-utils"), require("stockcars-utils-bundle"), require("stockcars-bundle"), require("core-application"));
	else if(typeof define === 'function' && define.amd)
		define("stockcars-suggested-cars-bundle", ["core-utils", "stockcars-utils-bundle", "stockcars-bundle", "core-application"], factory);
	else if(typeof exports === 'object')
		exports["stockcars-suggested-cars-bundle"] = factory(require("core-utils"), require("stockcars-utils-bundle"), require("stockcars-bundle"), require("core-application"));
	else
		root["stockcars-suggested-cars-bundle"] = factory(root["core-utils"], root["stockcars-utils-bundle"], root["stockcars-bundle"], root["core-application"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__) {
return 