import {appEvents} from 'core-application';
import {dom as DOM_UTILS, template as TPL_ENGINE} from 'core-utils';
import {STCK_STORE, SELECTORS, ACTIONS} from '@oneaudi/stck-store';
import SuggestedVehicleDTO from './suggested-vehicle-dto';
import {scsController, vehiclesController, ScViewTilesTemplateLookup} from '../stockcars-bundle';
import {lazyLoad, scPage} from '../stockcars-utils-bundle';

export default class ScSuggestedCarsElement extends HTMLElement {
	constructor() {
		super();
		this._SHOWVEHICLES = 0;
		this._VIEWPORT = 'desktop';
		this.tabRenderDataMap = null;
		this.tileTabs = this.dataset.tileTabs;
	}

	static get defaults() {
		return {
			moduleSelector: 'sc-suggested-cars-element',
			classMain: '.sc-md-suggested-cars',
			classMainUsedCars: 'sc-suggested-cars-used-cars',
			classPanelWrapper: '.sc-panel-wrapper',
			classModuleWrapper: '.sc-module-wrapper',
			classModulePanelTab: '.sc-md-suggested-cars .sc-panel-tabs label',
			classModulePanelInputs: '.sc-md-suggested-cars .sc-suggested-cars-input',
			classArrowPrev: '.sc-suggested-cars-prev',
			classArrowNext: '.sc-suggested-cars-next',
			classModuleSlider: '.sc-suggested-cars-list',
			classModuleSliderItems: '.sc-md-suggested-cars .sc-tiles-item',
			classModulesSliderIndicators: '.sc-suggested-cars-indicator-dots',
			TOLERANCE_VALUE: 15,
			SUGGESTIONTYPE_VISITED: 'visited',
			SUGGESTIONTYPE_SIMILAR: 'similar',
			SUGGESTIONTYPE_SIMILAR_DEALERS: 'similar-dealers',
			NUMBER_OF_VEHICLES_TO_FETCH: 9,
			selectorMain: '.sc-md-suggested-cars',
			selectorPaging: '.sc-detail-paging-container'
		};
	}

	/**
	 * returns detail link url
	 * @method getDetailsLinkUrl
	 * @param {string} data_attribute_ - name of the data attribute
	 * @return {string} returns url
	 */
	static getDetailsLinkUrl(data_attribute_) {
		let el = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.selectorMain);
		if (!DOM_UTILS.isElement(el)) {
			el = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.selectorPaging);
		}
		return el.getAttribute(data_attribute_) || null;
	}

	/**
	 * sets Details url
	 *
	 * @method setDetailsURL
	 * @param {string} _vehicleId - id of the current vehicle
	 * @return {string} patched url string for detail link
	 */
	static setDetailsUrl(_vehicleId) {
		let url = ScSuggestedCarsElement.getDetailsLinkUrl('data-details-url') || ScSuggestedCarsElement.getDetailsLinkUrl('data-default-url') || '';
		if (url.indexOf('SC_VEHICLE_ID') !== -1) {
			url = url.replace(/SC_VEHICLE_ID/i, _vehicleId);
		}
		return url;
	}


	/**
	 * fetch matching vehicles from SCS by vehicleID
	 * @param {string} vehicleId_ - vehicle's id
	 * @param {boolean} onlyFromDealer - fetch only matching cars from this dealer
	 * @returns {Promise} vehicles array
	 */
	async fetchMatchingVehicles(vehicleId_, onlyFromDealer = false) {
		try {
			const fnParams = [vehicleId_, ScSuggestedCarsElement.defaults.NUMBER_OF_VEHICLES_TO_FETCH];
			if (onlyFromDealer) {
				const dealerResponse = await vehiclesController.loadVehicleById(vehicleId_);
				const dealerID = dealerResponse.dealer.id;
				fnParams.push(dealerID);
			}
			let [vehicleId, size, dealerId] = fnParams;
			const vehicleResponse = await scsController.fetchMatchingVehicleData(vehicleId, size, dealerId);
			const vehiclesArray = vehicleResponse && vehicleResponse.vehicleBasic ? vehicleResponse.vehicleBasic : [];
			STCK_STORE.dispatch(ACTIONS.VEHICLES.setVehicles({vehicles: vehiclesArray}));
			const vehicleIds = vehiclesArray.map(vehicle => vehicle.id);
			STCK_STORE.dispatch(onlyFromDealer ? ACTIONS.MATCHING_DEALER_VEHICLES.setMatchingDealerVehicleIds({vehicleIds}) : ACTIONS.MATCHING_VEHICLES.setMatchingVehicleIds({vehicleIds}));
			return vehiclesArray;
		}
		catch (err) {
			console.warn('could not fetch matching vehicles', err);
			return [];
		}
	}

	/** if the user hits a detail page directly and has visited autos on
	 * localstorage, it can be that the visited vehicles are not present on the
	 * store. This function will detect that situation, fetch the missing
	 * vehicles from SCS if needed and update the store.
	 *
	 * @returns {Array} - full last visited vehicles data
	 */
	async fetchVisitedVehicles() {
		try {
			let storedVisitedVehicles = [...SELECTORS.VEHICLES.getLastVisitedVehiclesMap(STCK_STORE.state).values()];
			const lastVisitedVehicleIds = SELECTORS.VEHICLES.getLastVisitedVehicleIdsState(STCK_STORE.state);
			if (lastVisitedVehicleIds.length && (storedVisitedVehicles.length !== lastVisitedVehicleIds.length)) {
				const visitedVehiclesResponse = await scsController.fetchVehiclesByIds(lastVisitedVehicleIds, 'modelName:asc');
				storedVisitedVehicles = visitedVehiclesResponse && visitedVehiclesResponse.vehicleBasic || [];
				STCK_STORE.dispatch(ACTIONS.VEHICLES.setVehicles({storedVisitedVehicles}));
			}
			return storedVisitedVehicles;
		}
		catch (err) {
			console.warn('could not fetch visited vehicles, the last visited autos tab on the recommended autos section may be empty', err);
			return [];
		}
	}


	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this._removeEvents();
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	async connectedCallback() {
		// prevent initializing in layer context
		if (scPage.isElementInLayerContext(this)) {
			return;
		}

		this._addEvents();

		try {
			const vehicleID = ScSuggestedCarsElement.getVehicleIdFromUrl(location.href);
			this.layoutType = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classMain).getAttribute('data-market') || '';
			this.tabRenderDataMap = await this._fetchData(vehicleID);
			this._setupModule();
		}
		catch (e) {
			console.warn(e);
		}
	}

	/**
	 * Gets the ID of Current URL
	 * @method getVehicleIdFromUrl
	 * @param {string} url_ url
	 * @returns {string} vehicleID
	 */
	static getVehicleIdFromUrl(url_ = '') {
		var regex = /(sc_detail.*?\.)(([^.\s])+)/gi,
			matchesArr,
			vehicleIds = [];
		// loop all matches and push id into vehicleIds array
		while ((matchesArr = regex.exec(url_))) {
			vehicleIds.push(matchesArr[2]);
		}
		// return only the last id - needed for detail page in layer on top of detail page
		return vehicleIds[vehicleIds.length - 1] || '';
	}

	/**
	 * fetch data for all tabs
	 * @param {string} vehicleId vehicleId
	 * @returns {void} nothing
	 */
	async _fetchData(vehicleId) {
		// wait for filter/items to be loaded to get/validate initial presets
		await STCK_STORE.waitForStateFromStorePromise({
			conditionFn: (state) => (state.filters.length),
			timeOut: (5000)
		}).then(()=>console.warn('filter/Items loaded')).catch((err)=>{ console.warn('initial filtes are missing'); throw err; });

		const suggestionTypesArr = this._getSuggestionTypes();
		let lastVisitedVehiclesArray = await this.fetchVisitedVehicles();
		const promiseArray = suggestionTypesArr.map((type) => {
			if (type === ScSuggestedCarsElement.defaults.SUGGESTIONTYPE_SIMILAR_DEALERS) {
				return this.fetchMatchingVehicles(vehicleId, true);
			}
			if (type === ScSuggestedCarsElement.defaults.SUGGESTIONTYPE_SIMILAR) {
				return this.fetchMatchingVehicles(vehicleId, false);
			}
			if (type === ScSuggestedCarsElement.defaults.SUGGESTIONTYPE_VISITED) {
				const lastVisitedVehiclesArrayWithoutCurrentVehicle = lastVisitedVehiclesArray.reverse().filter((vehicle) => vehicle.id !== vehicleId);
				return lastVisitedVehiclesArrayWithoutCurrentVehicle;
			}
		});
		const tabRenderDataMap = new Map();
		const resultArrays = await Promise.all(promiseArray);
		resultArrays.forEach((vehiclesArray, index) => tabRenderDataMap.set(suggestionTypesArr[index], this._convertVehiclesToSuggestedCarsDTOs(vehiclesArray)));
		return tabRenderDataMap;
	}

	/**
	 * prepares received matching result
	 * @method onMatchingResultReceived
	 * @param {Object} vehiclesArray_ - vehicles response object
	 * @returns {Array} - returns array of vehicle items
	 */
	_convertVehiclesToSuggestedCarsDTOs(vehiclesArray_) {
		const vehiclesArray = [];
		vehiclesArray_.forEach((vehicle) => {
			const vehicleDTO = new SuggestedVehicleDTO(vehicle, ScSuggestedCarsElement.setDetailsUrl(vehicle.id), this.tileTabs);
			vehiclesArray.push(vehicleDTO.vehicleData);
		});
		return vehiclesArray;
	}

	/**
	 * addEvents
	 * @returns {void} returns nothing
	 */
	_addEvents() {
		this._bindContextToFunctions();

		const _domEventDelegate = DOM_UTILS.getEventDelegate('body');
		_domEventDelegate.on('click', ScSuggestedCarsElement.defaults.classArrowNext, this._sliderArrowClickHandler);
		_domEventDelegate.on('click', ScSuggestedCarsElement.defaults.classArrowPrev, this._sliderArrowClickHandler);
		_domEventDelegate.on('click', ScSuggestedCarsElement.defaults.classModulesSliderIndicators + ' li', this._indicatorClickHandler);
		_domEventDelegate.on('change', ScSuggestedCarsElement.defaults.classModulePanelInputs, this._onTabChangeHandler);
		_domEventDelegate.on('scroll', ScSuggestedCarsElement.defaults.classPanelWrapper, this._scrollHandler);
		window.addEventListener('resize', this._onWindowResizeHandler);
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	_removeEvents() {
		const _domEventDelegate = DOM_UTILS.getEventDelegate('body');
		_domEventDelegate.off('click', ScSuggestedCarsElement.defaults.classArrowNext, this._sliderArrowClickHandler);
		_domEventDelegate.off('click', ScSuggestedCarsElement.defaults.classArrowPrev, this._sliderArrowClickHandler);
		_domEventDelegate.off('click', ScSuggestedCarsElement.defaults.classModulesSliderIndicators + ' li', this._indicatorClickHandler);
		_domEventDelegate.off('change', ScSuggestedCarsElement.defaults.classModulePanelInputs, this._onTabChangeHandler);
		_domEventDelegate.off('scroll', ScSuggestedCarsElement.defaults.classPanelWrapper, this._scrollHandler);
		window.removeEventListener('resize', this._onWindowResizeHandler);
	}

	/**
	 * update
	 * public update method, gets called from observed model
	 * @returns {void} nothing
	 */
	update() {
		this._setupModule();
	}

	/**
	 * _bindContextToFunctions - bind context to necessary functions
	 * @private
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._sliderArrowClickHandler = this._sliderArrowClickHandler.bind(this);
		this._indicatorClickHandler = this._indicatorClickHandler.bind(this);
		this._onTabChangeHandler = this._onTabChangeHandler.bind(this);

		this._scrollHandler = this._scrollHandler.bind(this);
		this._scrollHandler = DOM_UTILS.throttle(this._scrollHandler, 600);

		this._onWindowResizeHandler = this._onWindowResizeHandler.bind(this);
		this._onWindowResizeHandler = DOM_UTILS.throttle(this._onWindowResizeHandler, 50);

		this._fetchData = this._fetchData.bind(this);
	}

	/**
	 * Listener for window scroll
	 * called if scroll is thrown
	 * @method scrollHandler
	 * @param {Event} event_ - scroll event
	 * @return {void} returns nothing
	 */
	_scrollHandler(event_) {
		if (event_ && event_.target) {
			const sliderWrapper = event_.target;
			const moduleType = sliderWrapper.getAttribute('data-type');
			const module = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classModuleSlider + '[data-type="' + moduleType + '"]');
			this._refreshModuleNavigationElements(module);
		}
	}

	/**
	 * Indicator Clickhandler
	 * @method indicatorClickHandler
	 * @param {Event} event_ - click event
	 * @return {void} returns nothing
	 */
	_indicatorClickHandler(event_) {
		const panelWrapper = DOM_UTILS.closest(event_.target, ScSuggestedCarsElement.defaults.classPanelWrapper);
		const wrapper = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classModuleWrapper, panelWrapper);
		const slider = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classModuleSlider, panelWrapper);
		const item = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classModuleSliderItems, wrapper);
		const itemWidth = item.clientWidth;
		const itemIndex = event_.target.getAttribute('data-index');
		let itemScrollLeft = parseInt(itemIndex, 10) * itemWidth;
		const maxDistance = (this._sliderWidth ? this._sliderWidth : slider.clientWidth) - (this._sliderWrapWidth ? this._sliderWrapWidth : wrapper.clientWidth);
		itemScrollLeft = itemScrollLeft > maxDistance ? maxDistance : itemScrollLeft;
		DOM_UTILS.animateElementX(itemScrollLeft, wrapper, 600);
	}

	/**
	 * get an array of all suggested cars types similar, visited, similar-dealers
	 * @returns {array} array with suggested cars types
	 */
	_getSuggestionTypes() {
		const modules = DOM_UTILS.getElementsArray(ScSuggestedCarsElement.defaults.classPanelWrapper + ' ' + ScSuggestedCarsElement.defaults.classModuleSlider, this);
		return modules.map((module) => module.getAttribute('data-type'));
	}

	/**
	 * setup module(s)
	 * @method setupModule
	 * @return {void} returns nothing
	 */
	async _setupModule() {
		const modules = DOM_UTILS.getElementsArray(ScSuggestedCarsElement.defaults.classPanelWrapper + ' ' + ScSuggestedCarsElement.defaults.classModuleSlider, this);
		this._setViewport();

		const typeModules = await this._renderTypeModules(modules);
		this._setStatesAfterInitialRendering(typeModules);

		const contentRendered = new CustomEvent(appEvents.CONTENT_RENDERED, {
			element: this,
			type: 'module',
			detail: {element: this}
		});
		document.dispatchEvent(contentRendered);
	}

	async _renderTypeModules(modules_) {
		const modules = modules_.map(async (module) => {
			const type = module.getAttribute('data-type');
			return await this._render(module, type);
		});
		const typeModules = await Promise.all(modules);
		return typeModules;
	}

	/**
	 * setStatesAfterInitialRendering
	 * @param {Array} typeModules typeModules
	 * @returns {void} returns nothing
	 */
	_setStatesAfterInitialRendering(typeModules) {
		let checked = false;
		typeModules.forEach((module) => {
			if (module.vehicleData.length > 0) {
				let input = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classModulePanelInputs + '[data-type=' + module.type + ']', this);
				if (!checked) {
					checked = true;
					input.checked = true;
					this._setModulesWidth(module.type);
				}
				else {
					input.checked = false;
				}
				const activeModule = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classModuleSlider + '[data-type="' + module.type + '"]', this);
				this._refreshModuleNavigationElements(activeModule);
				this.classList.remove('sc-suggested-cars-hidden');
			}
		});
	}

	/**
	 * Listener for on window resize
	 * called if window.onresize is thrown
	 * @method onUpdateViewHandler
	 * @return {void} returns nothing
	 */
	_onWindowResizeHandler() {
		this._setViewport();
		const checkedInput = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classModulePanelInputs + ':checked');
		const moduleType = checkedInput.getAttribute('data-type');
		const module = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classModuleSlider + '[data-type="' + moduleType + '"]');
		this._setModulesWidth(moduleType);
		this._refreshModuleNavigationElements(module);
	}

	/**
	 * Tab Change Handler
	 * @method onTabChangeHandler
	 * @param {Event} event_ - change event
	 * @return {void} returns nothing
	 */
	_onTabChangeHandler(event_) {
		const type = event_.target.getAttribute('data-type'),
			module = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classModuleSlider + '[data-type="' + type + '"]');
		this._refreshModuleNavigationElements(module);
	}

	/**
	 * setAllModulesPostRenderingStates
	 * @param {Array} modulesArray_ modulesArray_
	 * @returns {void} returns nothing
	 */
	_setAllModulesPostRenderingStates(modulesArray_) {
		modulesArray_.forEach((module) => {
			this._refreshModuleNavigationElements(module);
		});
	}

	/**
	 * refreshModuleNavigationElements
	 * @param {HtmlElement} module_ - active module_
	 * @returns {void} returns nothing
	 */
	_refreshModuleNavigationElements(module_) {
		const panelWrapper = DOM_UTILS.closest(module_, ScSuggestedCarsElement.defaults.classPanelWrapper),
			sliderItems = DOM_UTILS.getElementsArray(ScSuggestedCarsElement.defaults.classModuleSliderItems, module_),
			controls = {};
		controls.prev = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classArrowPrev, panelWrapper);
		controls.next = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classArrowNext, panelWrapper);
		this._showIndicatorsByViewport(module_);
		this._setIndicatorActiveState(module_);
		this._hideSliderNavigation(sliderItems, controls, module_.parentNode);
	}

	/**
	 * render the DOM
	 * @method render
	 * @param {HTMLElement} module_ - context element
	 * @param {string} type_ - lastVisited, suggestedCars or suggestedCarsFromDealer
	 * @return {object} {type,vehicleData}
	 */
	async _render(module_, type_) { // eslint-disable-line max-statements
		let itemsString;
		const panelWrapper = DOM_UTILS.closest(module_, ScSuggestedCarsElement.defaults.classPanelWrapper);
		const dots = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classModulesSliderIndicators, panelWrapper);
		let vehicleDataArray = this.tabRenderDataMap.get(type_);
		let vehiclesDataArray = this._addAvailableFromFlag(vehicleDataArray);
		if (vehiclesDataArray.length) {
			const template = ScViewTilesTemplateLookup.getTemplate(this.layoutType);
			itemsString = TPL_ENGINE.render(template, {
				data: vehiclesDataArray
			});
			if (module_ && itemsString) {
				module_.innerHTML = '';
				DOM_UTILS.appendHtmlString(module_, itemsString);
				dots.innerHTML = this._createIndicatorDots(vehicleDataArray);
			}
			this._handleTabVisibility(module_, type_, true);
		}
		else {
			this._handleTabVisibility(module_, type_, false);
		}
		lazyLoad.register(this);
		return {
			type: type_,
			vehicleData: vehicleDataArray
		};
	}

	/**
	 * addAvailableFromFlag
	 * @method addAvailableFromFlag
	 * @param {Array} vehicles_ vehicle array for checking if its elements are electrical
	 * @returns {Array} prepared array
	 */
	_addAvailableFromFlag(vehicles_) {
		vehicles_.forEach((vehicle) => {
			vehicle.isAvailableSoonVehicle = scPage.isAvailableSoonVehicle(vehicle);
		});
		return vehicles_;
	}


	/**
	 * show indicators by viewport
	 * @method showIndicatorsByViewport
	 * @param {HTMLElement} module_ - module wrapper
	 * @return {void} returns nothing
	 */
	_showIndicatorsByViewport(module_) {
		const panelWrapper = DOM_UTILS.closest(module_, ScSuggestedCarsElement.defaults.classPanelWrapper);
		this._indicators = DOM_UTILS.getElementsArray(ScSuggestedCarsElement.defaults.classModulesSliderIndicators + ' li', panelWrapper);
		this._indicators.forEach((dot, index) => {
			dot.classList.add('nm-hidden');
			if (this._VIEWPORT === 'desktop' && this._indicators.length > 3 && (index === 0 || index % 3 === 0)) {
				dot.classList.remove('nm-hidden');
			}
			if (this._VIEWPORT === 'tablet' && this._indicators.length > 2 && index % 2 === 0) {
				dot.classList.remove('nm-hidden');
			}
			if (this._VIEWPORT === 'mobile' && this._indicators.length > 1) {
				dot.classList.remove('nm-hidden');
			}
		});
	}

	/**
	 * set indicator active state
	 * @method setIndicatorActiveState
	 * @param {HTMLElement} module_ - module wrapper
	 * @return {void} returns nothing
	 */
	_setIndicatorActiveState(module_) {
		const panelWrapper = DOM_UTILS.closest(module_, ScSuggestedCarsElement.defaults.classPanelWrapper),
			indicators = DOM_UTILS.getElementsArray(ScSuggestedCarsElement.defaults.classModulesSliderIndicators + ' li', panelWrapper);
		this._vehicles = DOM_UTILS.getElementsArray(ScSuggestedCarsElement.defaults.classModuleSliderItems, module_);
		this._vehicles.forEach((vehicle, index) => {
			indicators[index].classList.remove('active');
			const status = this._getItemVisiblityStatus(vehicle, panelWrapper);
			if (status.visibleStatus === 2 || (status.visibleStatus === 1 && status.nearestParentBoundPercentage > 30)) {
				if (
					(this._VIEWPORT === 'desktop' && this._vehicles.length > 3) || (this._VIEWPORT === 'tablet' && this._vehicles.length > 2) || (this._VIEWPORT === 'mobile' && this._vehicles.length > 1)) {
					indicators[index].classList.add('active');
				}
			}
		});
	}

	/**
	 * create dot indicators
	 * @method createIndicatorDots
	 * @param {array} vehicleArray_ - vehicles array
	 * @return {HTMLString} returns HTMLString
	 */
	_createIndicatorDots(vehicleArray_) {
		let html = '';
		if (vehicleArray_) {
			vehicleArray_.forEach((_, index) => {
				html += '<li data-index=\'' + index + '\' class=\'nm-hidden\'></li>';
			});
		}
		return html;
	}

	/**
	 * hideSliderNavigation
	 * @param {Array} vehicles_ Array with slider elements (HTMLElements)
	 * @param {HTMLElement} arrows_ slider navigation arrows
	 * @param {HTMLElement} wrapper_ slider wrapper
	 * @return {Void} returns nothing
	 */
	_hideSliderNavigation(vehicles_, arrows_, wrapper_) {
		if (!!vehicles_ && vehicles_.length > 1) {
			const prev = this._getItemVisiblityStatus(vehicles_[0], wrapper_);
			const next = this._getItemVisiblityStatus(vehicles_[vehicles_.length - 1], wrapper_, 10);
			if (prev.visibleStatus === 0 || (prev.visibleStatus === 1 && prev.nearestParentBoundPercentage < 100)) {
				arrows_.prev.classList.remove('nm-hidden');
			}
			else {
				arrows_.prev.classList.add('nm-hidden');
			}
			if (next.visibleStatus === 2) {
				arrows_.next.classList.add('nm-hidden');
			}
			else {
				arrows_.next.classList.remove('nm-hidden');
			}
		}
		else {
			arrows_.prev.classList.add('nm-hidden');
			arrows_.next.classList.add('nm-hidden');
		}
	}

	/**
	 * Add favorite status before rendering
	 * @param {Object} resultObjArray - Array of vehicle objects
	 * @returns {Object} - resultObjArray - return Array of vehicle objects
	 */
	_addFavoriteStatus(resultObjArray = []) {
		resultObjArray.forEach((vehicle) => {
			vehicle.favorite = SELECTORS.VEHICLES.isFavoriteVehicle(STCK_STORE.state, vehicle.id);
		});
		return resultObjArray;
	}

	/**
	 * sets viewport variable
	 * used for indicators + number of vehicles  shown in slider
	 * @method setViewport
	 * @return {void} returns nothing
	 */
	_setViewport() {
		const windowWidth = window.innerWidth;
		if (windowWidth && windowWidth < 568) {
			this._VIEWPORT = 'mobile';
			this._SHOWVEHICLES = 1;
		}
		else if (windowWidth && windowWidth > 568 && windowWidth < 1024) {
			this._SHOWVEHICLES = 2;
			this._VIEWPORT = 'tablet';
		}
		else {
			this._SHOWVEHICLES = 3;
			this._VIEWPORT = 'desktop';
		}
	}

	/**
	 * set width for all slider modules
	 * @method setModulesWidth
	 * @param {string} type_ module type
	 * @return {void} returns nothing
	 */
	_setModulesWidth(type_) {
		let moduleWrapperWidth = DOM_UTILS.getElement('.sc-suggested-cars-input[data-type=' + type_ + '] + label + .sc-panel-wrapper', this).clientWidth;
		const modules = DOM_UTILS.getElementsArray('.sc-suggested-cars-list', this),
			vehicles = DOM_UTILS.getElementsArray('.sc-suggested-cars-list .sc-j-car-tile', this);
		if (DOM_UTILS.isElement(DOM_UTILS.getElement('.sc-panel-wrapper-single', this))) {
			moduleWrapperWidth = DOM_UTILS.getElement('.sc-panel-wrapper-single', this).clientWidth;
		}
		const vehiclesWidth = Math.round(moduleWrapperWidth / this._SHOWVEHICLES);

		modules.forEach((module_) => {
			// make it 3px bigger for rounding issues
			module_.style.width = 3 + module_.childNodes.length * vehiclesWidth + 'px';
		});

		vehicles.forEach((vehicle_) => {
			vehicle_.style.width = vehiclesWidth + 'px';
		});
	}

	/**
	 * hide tab if there is no content
	 * @method hideTab
	 * @param {HTMLElement} module_ - current module
	 * @param {string} type_ - suggested cars type
	 * @param {string} show_ - hast content?
	 * @return {void} returns nothing
	 */
	_handleTabVisibility(module_, type_, show_) {
		const wrapper = DOM_UTILS.closest(module_, '.sc-panel-' + type_);
		const tab = DOM_UTILS.getElementsArray('.sc-panel-tab-' + type_, this);
		if (wrapper) {
			if (show_) {
				wrapper.classList.remove('js-hidden');
			}
			else {
				wrapper.classList.add('js-hidden');
			}
		}
		if (tab.length !== 0) {
			tab.forEach((item) => {
				if (show_) {
					item.classList.remove('js-hidden');
				}
				else {
					item.classList.add('js-hidden');
				}
			});
		}
	}


	/**
	 * getCurrentSlider
	 * @param {HTMLElement} event_ target to get closest module
	 * @return {Void} returns nothing
	 */
	_getCurrentSlider(event_) {
		if (event_) {
			return DOM_UTILS.closest(event_.target, ScSuggestedCarsElement.defaults.classPanelWrapper);
		}
		else {
			return document;
		}
	}

	/**
	 * sliderArrowClickHandler
	 * @param {Event} event_ event to set this._currentGallery
	 * @return {Void} returns nothing
	 */
	_sliderArrowClickHandler(event_) {
		let tmpScrollToValue;
		if (event_ && event_.preventDefault) {
			event_.preventDefault();
		}
		let scrollToValue = 0;
		const direction = event_.target.getAttribute('data-direction'),
			slider = DOM_UTILS.getElement(ScSuggestedCarsElement.defaults.classModuleSlider, this._getCurrentSlider(event_)),
			sliderElements = DOM_UTILS.getElementsArray(ScSuggestedCarsElement.defaults.classModuleSlider + ' li', this._getCurrentSlider(event_)),
			sliderWrap = slider.parentNode,
			left = sliderWrap.scrollLeft;
		this._sliderWrapWidth = sliderWrap.clientWidth;
		this._sliderWidth = slider.clientWidth;
		const maxDistance = this._sliderWidth - this._sliderWrapWidth;
		const width = this._calcScrollWidth(sliderWrap, sliderElements, direction);
		if (direction === 'prev') {
			// GO LEFT
			tmpScrollToValue = left - width;
			scrollToValue = tmpScrollToValue < ScSuggestedCarsElement.defaults.TOLERANCE_VALUE ? 0 : tmpScrollToValue;
		}
		else {
			// GO RIGHT
			tmpScrollToValue = left + width;
			scrollToValue = tmpScrollToValue > maxDistance - ScSuggestedCarsElement.defaults.TOLERANCE_VALUE ? maxDistance : tmpScrollToValue;
		}
		DOM_UTILS.animateElementX(scrollToValue, sliderWrap, 600);
	}

	/**
	 * createBoundingObject
	 * @param {Object} parentBound_ - object with boundings of parent element
	 * @param {Object} rectBound_ - object with boundings of element
	 * @param {boolean} leftBoundVisible_ - left bound is visible?
	 * @param {boolean} rightBoundVisible_ - right bound is visible?
	 * @return {Object} returns bounding object
	 * @example
	 *
	 * boundingObject = { 	'elemWidth': <Number> in Pixels,
	 *						'nearestParentBound': <String> 'left'/'right',
	 *						'nearestParentBoundPercentage': <Number in Percentage>,
	 *						'visible': <Boolean>,
	 *						'visibleStatus': <Number> (0 = not visible, 1 = half visible, 2 = full visible),
	 *						'visibleStatusText': <String> ('not visible', 'half visible', 'full visible')
	 *					};
	 */
	_createBoundingObject(parentBound_, rectBound_, leftBoundVisible_, rightBoundVisible_) { // eslint-disable-line max-statements
		const boundingObject = {};
		if (rightBoundVisible_ && leftBoundVisible_) {
			boundingObject.visible = true;
			boundingObject.visibleStatus = 2;
			boundingObject.visibleStatusText = 'full';
		}
		else if (rightBoundVisible_ || leftBoundVisible_) {
			boundingObject.visible = true;
			boundingObject.visibleStatus = 1;
			boundingObject.visibleStatusText = 'half';
		}
		else {
			boundingObject.visible = false;
			boundingObject.visibleStatus = 0;
			boundingObject.visibleStatusText = 'no';
		}
		boundingObject.elemWidth = Math.round(rectBound_.width);
		if (rectBound_.right < parentBound_.right && rectBound_.right < parentBound_.right - parentBound_.width / 2) {
			boundingObject.nearestParentBound = 'left';
			boundingObject.nearestParentBoundPercentage = Math.round(Math.abs((rectBound_.right - parentBound_.left) / rectBound_.width) * 100);
		}
		else {
			boundingObject.nearestParentBound = 'right';
			boundingObject.nearestParentBoundPercentage = Math.round(Math.abs((parentBound_.right - rectBound_.left) / rectBound_.width) * 100);
		}
		return boundingObject;
	}

	/**
	 * getItemVisiblityStatus
	 * @param {HtmlElement} element_ to check if visible
	 * @param {HtmlElement} parent_ parent node
	 * @param {HtmlElement} approximation_ approximation at the edges (default = 0)
	 * @return {Object} returns bounding object
	 */
	_getItemVisiblityStatus(element_, parent_, approximation_) {
		let parentBound,
			rectBound,
			rightBoundVisible,
			leftBoundVisible,
			approximation = approximation_ || 0;
		parentBound = parent_.getBoundingClientRect();
		rectBound = element_.getBoundingClientRect();
		rightBoundVisible = (Math.round(rectBound.right) <= Math.round(parentBound.right) || (Math.round(Math.abs(rectBound.right / parentBound.right) * 100) >= 100 - approximation && Math.round(Math.abs(rectBound.right / parentBound.right) * 100) <= 100 + approximation)) && Math.round(rectBound.right) >= Math.round(parentBound.left);
		leftBoundVisible = (Math.round(rectBound.left) >= Math.round(parentBound.left) || (Math.round(Math.abs(rectBound.left / parentBound.left) * 100) >= 100 - approximation && Math.round(Math.abs(rectBound.left / parentBound.left) * 100) <= 100 + approximation)) && Math.round(rectBound.left) <= Math.round(parentBound.right);
		return this._createBoundingObject(parentBound, rectBound, leftBoundVisible, rightBoundVisible);
	}

	/**
	 * calcScrollWidth
	 * @param {HTMLElement} wrapper_ item wrapper
	 * @param {Array} items_ - array of HtmlElemtens
	 * @param {string} direction_ - scroll direction
	 * @return {Number} returns number
	 */
	_calcScrollWidth(wrapper_, items_, direction_) {
		let width = 0,
			status,
			wrapper = wrapper_.getBoundingClientRect(),
			wrapperLeft = wrapper.left,
			wrapperRight = wrapper.right,
			tmpEdge,
			visibleItemsWithStatusTwo = [],
			visibleItemsWithStatusOne = [];

		items_.forEach((item) => {
			status = this._getItemVisiblityStatus(item, wrapper_, 2);
			if (status.visibleStatus === 2) {
				visibleItemsWithStatusTwo.push(item);
			}
			else if (status.visibleStatus === 1) {
				visibleItemsWithStatusOne.push(item);
			}

		});

		if (direction_ === this._PREV) {
			if (visibleItemsWithStatusTwo.length > 0) {
				tmpEdge = visibleItemsWithStatusTwo[0].getBoundingClientRect().left;
				width = wrapperRight - tmpEdge;
			}
			else if (visibleItemsWithStatusOne.length > 0) {
				tmpEdge = visibleItemsWithStatusOne[visibleItemsWithStatusOne.length - 1].getBoundingClientRect().left;
				width = wrapperRight - tmpEdge;
			}
		}
		else {
			if (visibleItemsWithStatusTwo.length > 0) {
				tmpEdge = visibleItemsWithStatusTwo[visibleItemsWithStatusTwo.length - 1].getBoundingClientRect().right;
				width = tmpEdge - wrapperLeft;
			}
			else if (visibleItemsWithStatusOne.length > 0) {
				tmpEdge = visibleItemsWithStatusOne[0].getBoundingClientRect().right;
				width = tmpEdge - wrapperLeft;
			}
		}
		return width;
	}

}

if (window.customElements.get('sc-suggested-cars-element') === undefined) {
	window.customElements.define('sc-suggested-cars-element', ScSuggestedCarsElement);
}
