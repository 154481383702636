import {globalEventEmitter} from "../stockcars-bundle";
import {CoreEvents} from '@audi/audi-core-events';
import {STCK_STORE, SELECTORS, ACTIONS} from '@oneaudi/stck-store';


class ScControllerLastVisitedCarsClass {
	constructor() {
		this.globalEventBus = globalEventEmitter;
		this.addEvents();
		this.checkForNewCar();
	}

	addEvents() {
		this.checkForNewCar = this.checkForNewCar.bind(this);
		this.globalEventBus.on(CoreEvents.LAYER_LOADED, this.checkForNewCar);
		this.globalEventBus.on(CoreEvents.PAGE_LOADED, this.checkForNewCar);
		this.globalEventBus.on(CoreEvents.PAGE_LOADED, ScControllerLastVisitedCarsClass.clearSuggestedCars);
	}

	removeEvents() {
		this.globalEventBus.off(CoreEvents.LAYER_LOADED, this.checkForNewCar);
		this.globalEventBus.off(CoreEvents.PAGE_LOADED, this.checkForNewCar);
		this.globalEventBus.off(CoreEvents.PAGE_LOADED, ScControllerLastVisitedCarsClass.clearSuggestedCars);

	}

	checkForNewCar() {
		const carId = this.getVehicleIdFromUrl(location.href);
		if (carId && SELECTORS.VEHICLES.getLastVisitedVehicleIdsState(STCK_STORE.state).indexOf(carId) === -1) { // eslint-disable-line no-bitwise
			STCK_STORE.dispatch(ACTIONS.LAST_VISITED.addVisitedVehicleId({vehicleId: carId}));
		}
	}

	static clearSuggestedCars() {
		STCK_STORE.dispatch(ACTIONS.MATCHING_DEALER_VEHICLES.removeMatchingDealerVehicleIds());
		STCK_STORE.dispatch(ACTIONS.MATCHING_VEHICLES.removeMatchingVehicleIds());
	}

	/**
	 * getVehicleIdFromUrl method
	 * @param {string} url_ - url
	 * @returns {string} vehicle ID
	 */
	getVehicleIdFromUrl(url_) {
		let regex = /(sc_detail.*?\.)(([^.\s])+)/gi,
			matchesArr,
			vehicleId,
			vehicleIds = [];
		// loop all matches and push id into vehicleIds array
		while ((matchesArr = regex.exec(url_))) {
			vehicleIds.push(matchesArr[2]);
		}
		// return only the last id - needed for detail page in layer on top of detail page
		vehicleId = vehicleIds[vehicleIds.length - 1] || '';
		// if url has param vehicleid, use this vehicleid instead of sc_detail vehicleid
		let match = url_.match(/(vehicleid=)(\D|\w)+&*/gi);
		if (!!match && match.length) {
			match = (match[0] || '').replace(/vehicleid=/, '');
			match = match.split('&').shift();
			vehicleId = match;
		}
		return vehicleId;
	}
}

const scControllerLastVisitedCars = new ScControllerLastVisitedCarsClass();
export {ScControllerLastVisitedCarsClass, scControllerLastVisitedCars};
